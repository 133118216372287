import gql from 'graphql-tag'

const mutation = gql`
  mutation updateWallet(
    $id: ID!
    $sequentialNumber: String!
  ) {
    updateWallet(
      id: $id
      sequentialNumber: $sequentialNumber
    ) {
      success
      errors {
        fieldName
        message
      }
    }
}`

/**
 * PaymentIntentID
 * @param {*} id
 * @param {*} sequentialNumber
 */
async function updateWallet (params) {
    const { $api } = Vue.prototype
    const result = await $api.mutate({
      mutation,
      variables: params
    }).then( res => res )
    .catch( e => e )
    return result;
}

export { updateWallet }
