import { mapActions } from 'vuex'
import { getWalletsList } from '@/queries/files/file.queries'
import { updateWallet } from '@/queries/paymentconfig/paymentconfig.mutations'

export default {
  name: 'TPaymentconfigPage',
  title: ({ title }) => title,
  components: {
    TCard: () => import('@/components/card'),
    THistoryTable: () => import('@/components/paymentconfig/history-table.vue')
  },
  data () {
    return {
      cardTitle: 'Configurações gerais',
      tooltipHtml: '',
      isLoading: false,
      wallets: [],
      editingForm: false
    }
  },
  methods: {
    ...mapActions('notifications', [ 'toggleNotification' ]),

    editShow: () => false,

    shortName (bankName) {
      const index = bankName.toLowerCase().indexOf('banco')
      if (index === -1 || bankName.toLowerCase().trim().includes('banco do brasil')) {
        return bankName
      }
      const shortName = `${bankName.slice(0, index)}${bankName.slice(index + 'banco'.length)}`
      if (shortName.length > 16) {
        return `${shortName.slice(0, 16)}...`
      }
      return shortName
    },

    editLine (wallet) {
      wallet.editing = !wallet.editing
      if (!this.editingForm) {
        this.editingForm = !this.editingForm
      }
    },

    resetForm () {
      if (this.wallets) {
        this.wallets.forEach((wallet) => {
          wallet.editing = false
        })
      }
      this.editingForm = false
    },

    save (walletId, sequencialNumber) {
      let wallet = this.wallets.find( wallet => wallet.id === walletId )
      if (wallet) {
        const params = {
          id: walletId,
          sequentialNumber: sequencialNumber
        }
        updateWallet(params).then(res => {
          const { success } = res.data.updateWallet
          let type = ''
          if (success) {
            type = 'success'
          } else {
            type = 'error'
          }
          this.resetForm()
          this.toggleNotification({ active: true, type })
          wallet.editing = false
        }).catch(e => {
          Vue.$log.error('error', e)
          wallet.editing = false
          this.toggleNotification({ active: true, type: 'error' })
        })
      }
    },

    searchWallets () {
      this.isLoading = true
      getWalletsList().then(result => {
        const { collection } = result.data.wallets
        this.wallets = collection.map((wallet) => ({
          ...wallet,
          editing: false
        }))
        this.isLoading = false
      }).catch( e => {
        this.isLoading = false
      })
    },

    bindPlugins () {
      $('.tui.popup').popup({
        transition: 'fade',
        distanceAway: 2,
        hoverable: true,
        popup : $('.history-table')
      })
    }
  },
  updated () {
    this.bindPlugins()
  },
  mounted () {
    setTimeout(() => {
      this.bindPlugins()
    }, 1000)

    this.searchWallets()
  }
}
